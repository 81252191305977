/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.scss in this case)
import '../css/global.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

// the bootstrap module doesn't export/return anything
require('bootstrap');
require('select2');
require('select2/dist/css/select2.min.css');
require('@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css');


// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');


$(document).ready(function () {
    $('[data-toggle="popover"]').popover();
    $('.select2-dropdown-user').select2(
        {
            ajax: {
                url: "/search_user",
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term, // search term
                        page: params.page,
                        active_only: ($(this).data('active-only') === 1 ? 1 : 0)
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                },
                cache: true
            },
            //placeholder: 'Search for a repository',
            minimumInputLength: 2,

            width: '100%',
            theme: 'bootstrap4',
            language: 'es',
            templateResult: formatUserWithAbsence
        }
    );

    function formatUserWithAbsence(user) {
        if (!user.id) {
            return user.text;
        }
        var $user = $('<span>' + user.text + (user.todayAbsence ? ' <span class="badge badge-danger">nieobecna/y</span>' : '') + '</span>');
        return $user;
    }

    $('.select2-dropdown-project').select2(
        {
            ajax: {
                url: "/search_project",
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                },
                cache: true
            },
            //placeholder: 'Search for a repository',
            minimumInputLength: 4,

            width: '100%',
            theme: 'bootstrap4',
            language: 'pl'
        }
    );
    $('.select2-dropdown').select2(
        {
            width: '100%',
            theme: 'bootstrap4'
        }
    );

    $('.custom-file-input').on('change', function (event) {
        var inputFile = event.currentTarget;
        $(inputFile).parent()
            .find('.custom-file-label')
            .html(inputFile.files[0].name);
    });

    $(document).ready(function () {
        $(".btn-loading").click(function () {
            $(this).html('<span class="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span><span class="align-middle"> Proszę czekać&hellip;</span>');
        });
        $('form').submit(function () {
            $(this).find('button[type="submit"]').attr('disabled', 'disabled');
            $(this).find('button[type="submit"]').html('<span class="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span><span class="align-middle"> Proszę czekać&hellip;</span>');
        });
    });
});